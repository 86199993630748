<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "do-payment",
  computed: {
    ...mapState({
      fp: state => state.fp,
    }),
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    params.set('usr', localStorage.getItem('web_user_id'));
    params.set('fp', this.fp.visitorId);
    window.location = 'https://n8n.kr3k.com/webhook/rt/make-payment?' + params.toString()
  },
  methods: {
    mergeQueryParams(url, newParams) {
      const urlObj = new URL(url);
      const params = new URLSearchParams(urlObj.href);
      console.log(params);

      // Обновляем параметры на основе переданного объекта
      for (const [key, value] of Object.entries(newParams)) {
        params.set(key, value);
      }

      // Заменяем параметры запроса в URL
      urlObj.search = params.toString();
      urlObj.host = 'n8n.kr3k.com'
      urlObj.pathname = 'webhook/rt/make-payment'
      return urlObj.toString();
    }
  }
})
</script>

<template>
  <pre>
  </pre>
</template>

<style scoped>

</style>