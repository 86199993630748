<template>
    <div v-if="selectOption && isLoaded" class="questionContainer">
        <header>
            <h1 class="title is-6">Выберите вариант</h1>
        </header>
        <div class="optionContainer">
            <router-link class="button" v-html="n.name" :to="'/q/' + n.id" v-for="n in options" :key="n"/>
        </div>
    </div>
    <test v-if="!selectOption && isLoaded" :option="selected" />
</template>
<script>
    import test from './test.vue';
	import quizService from '@/services/qiuz.js'
    /** @todo: unload from db */
    let option = 0;
    export default {
        name: "quiz-list",
        components: {
            test
        },
        data() {
            return {
                msg: 'test',
                options: option,
                selected: null,
                selectOption: true,
                isLoaded: false
            }
        },
        methods: {
            changeOption(n) {
                this.selected = n.toString();
                this.selectOption = false;
            },
            getOptions() {
                quizService
                .getOptionsData()
                .then(async (data) =>{
                    console.log(data);
                    this.options =  4;
                })
                .catch((error) => {
                    console.log(error)
                    return 3;
                })
            }
        },
        watch: {
            checkPromise() {
                if (this.drawer) {
                    this.menuOpened = true;
                    clearTimeout(this.menuOpenedTO);
                } else {
                    this.menuOpenedTO = setTimeout(() => (this.menuOpened = false), 200);
                }
            },
        },
        mounted() {
            quizService
                .getOptionsData()
                .then(async (data) => {
                  this.options = data.data.question.filter(r => Array.isArray(r.tags) && r.tags.includes('paid_test'));
                  this.isLoaded = true;
                })
                .catch((error) => {
                    console.log(error)
                    return 3;
                })
        }
    }
</script>
<style>
.quiz-option-box1 {
  height: 55px;
}
    .questionBox1 {
		
		max-width: 30rem;
		width: 30rem;
		min-height: 30rem;
		
		background: #FAFAFA;
		position: relative;
		display: flex;
		
		border-radius: 0.5rem;
		overflow: hidden;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		header{
			background:rgba(0,0,0,0.025);
			padding: 1.5rem;
			text-align: center;
			border-bottom: 1px solid rgba(0,0,0,0.1);
			
			h1{
				font-weight: bold;
				margin-bottom: 1rem !important;
			}
			.progressContainer {
				width: 60%;
				margin: 0 auto;
				>progress{
					margin:0;
					border-radius: 5rem;
					overflow: hidden;
					border:none;
					
					color: v-bind(primary_color);
					&::-moz-progress-bar { background: v-bind(primary_color); }
					&::-webkit-progress-value { background: v-bind(primary_color); }
				}
				>p{
					margin:0;
					margin-top: 0.5rem;
				}
			}
		}
		.titleContainer {
			text-align: center;
			margin: 0 auto;
			padding: 1.5rem;

		}

		.quizForm {
			display: block;
			white-space: normal;

			height: 100%;
			width: 100%;

			.quizFormContainer {
				height: 100%;
				margin: 15px 18px;

				.field-label {
					text-align: left;
					margin-bottom: 0.5rem;
				}
			}
		}
		.quizCompleted {
			width: 100%;
			padding: 1rem;
				text-align:center;
				
				> .icon{
					color: #FF5252;
					font-size: 5rem;
					
					.is-active{
						color: #00E676;
					}
				}
		}
		.questionContainer {
			white-space: normal;
			height: 100%;
			width: 100%;

			.optionContainer {
				margin-top: 12px;
				flex-grow: 1;
				.option {
					border-radius: 290486px;
					padding: 9px 18px;
					margin: 0 18px;
					margin-bottom: 12px;
					transition: $trans_duration;
					cursor: pointer;
					background-color: rgba(0, 0, 0, 0.05);
					color: rgba(0,0,0,0.85);
					border: transparent 1px solid;

					&.is-selected {
						border-color: rgba(black,0.25);
						background-color: white;
					}
					&:hover {
						background-color: rgba(0, 0, 0, 0.1);
					}
					&:active {
						transform: scaleX(0.9);
					}
				}
			}

			.questionFooter {
				background:rgba(0,0,0,0.025);
				border-top: 1px solid rgba(0,0,0,0.1);
				width: 100%;
				align-self: flex-end;

				.pagination {
					padding: 10px 15px;
					margin: 15px 25px;
				}
			}
		}
	}
</style>