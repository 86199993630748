import axios from "axios";
const API_ROOT = "/api/v1";
const api = {
   quiz: `${API_ROOT}/quiz`,
    options: `${API_ROOT}/options`,
    status: `${API_ROOT}/user/status`,
}

async function getQuizData(option) {
   try {
      const res = await axios.get(api.quiz, { params: { id: option } });
      return res;
   } catch (error) {
        console.log(error)
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new Error(message);
   }
}
async function getOptionsData() {
   try {
      const res = axios.get(api.options);
      return res;
   } catch (error) {
        console.log(error)
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new Error(message);
   }
}

async function getPaymentStatus(option) {
   try {
      const res = await axios.get(api.status, { params: { id: option } });
      return res;
   } catch (error) {
        console.log(error)
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new Error(message);
   }
}

function generateRandomString(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }

    return result;
}

export default {
       getQuizData,
    generateRandomString,
    getPaymentStatus,
    getOptionsData,
}