<template>
  <div>

    <qHeader v-if="!isFinished && attempt.q !== null" :nextAllowed="current_answer !== null"/>

    <!-- Экран начала тестирования -->
    <qStartScreen v-if="attempt.q === null && quiz.detailed_text"/>
      
    <!-- вопросы с ответами -->
    <div class="card" v-else-if="!isFinished" :key="attempt.q">
      <ul class="list-group list-group-flush">
        <li class="list-group-item pb-4">
          <p class="titleContainer" v-html="current_q.detailed_text" />
          <!-- варианты ответов -->
          <div class="d-grid gap-2 col-12 mx-auto ">

            <qAnswer v-for="(response, index) in current_q.answers" :key="index"
                :isCorrect="response.correct_answer"
                :haveAnswer="attempt.answers[attempt.q] === index"
                :response="response"
                :disabled="current_answer !== null"
                :index="index"/>
          </div>
        </li>
        <!-- подсказка к ответу -->
        <li class="list-group-item list-group-item-secondary p-0" v-if="current_answer !== null">

          <qAnswerExplanation 
            :description="current_q.wrong_description"
            :isCorrect="current_q.answers[current_answer].correct_answer"/>
          
        </li>

        <!-- футер с кнопкой "далее" -->
        <li class="list-group-item " v-if="current_answer !== null">
          <footer class="d-flex justify-content-end ">
            <button type="button" class="btn btn-primary " role="navigation" v-on:click="next();">
              {{ $t('testing.nextButton') }}
            </button>
          </footer>
        </li>
      </ul>

    </div>

    <!-- ответы закончились -->
    <qResults v-else :id="id"/>

  </div>
</template>

<script>
// import VueAudio from 'vue-audio';
import { mapActions, mapState, mapGetters } from "vuex";

import qHeader from './quiz/header.vue';
import qResults from './quiz/results.vue';
import qAnswer from './quiz/answer.vue';
import qAnswerExplanation from './quiz/answerExplanation.vue';
import qStartScreen from './quiz/startScreen.vue';

export default {
  name: "demoTesting",
  data() {
    return {
      questionIndex: 0,
      isActive: false,
      trans_duration: '0.3s',
      primary_color: '#3D5AFE',
      successScore: 2,
    }
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    'qHeader': qHeader,
    'qResults': qResults,
    'qAnswer': qAnswer,
    'qAnswerExplanation': qAnswerExplanation,
    'qStartScreen': qStartScreen
    // 'vue-audio': VueAudio
  },
  computed: {
    ...mapState({
      quiz: state => state.quiz.data,
      attempt: state => state.quiz.attempt,
    }),
    ...mapGetters({
      score: 'score'
    }),
    current_answer() {
      return this.attempt.answers[this.attempt.q] ?? null
    },
    current_q() {
      if (this.quiz.question.length && this.quiz.question[this.attempt.q]) {
        return this.quiz.question[this.attempt.q].question_id
      } else {return 11 }

    },
    option() {
      return this.$route.params.id ?? this.id
    },
    charIndex() {
      return (i) => {
        return String.fromCharCode(97 + i);
      }
    },
    isFinished(){      
      return !(this.attempt.q < this.quiz.question.length)
    }
  },
  methods: {
    ...mapActions(['loadQuiz', 'doQuestionAnswer', 'nextQuestion']),
    selectOption: function (answer) {
      if (this.current_answer === null)
        this.doQuestionAnswer({ answer })
    },
    next: function () {
      this.nextQuestion()
    },
  },
  mounted() {
    this.loadQuiz({ quiz_id: this.option })
  }

}
</script>

<style>

</style>