import {createRouter, createWebHistory} from "vue-router";
import test from "@/components/test.vue";
import quizList from "@/components/quiz-list.vue";
import store from "@/store";
import doPayment from "@/components/payment/do-payment.vue";
import diagnosePayment from "@/components/payment/diagnose-payment.vue";

export const router = createRouter({
    routes: [
        {
            path: '/',
            component: test,
            beforeEnter: (to, from, next) => {
                console.log(store.state.app.inited);
                if (store.state.app.inited) {
                    if (store.state.user.status) {
                        next('/list');  // Разрешаем доступ к маршруту
                    } else {
                        next('/demo');  // Перенаправляем на страницу логина
                    }
                }
            }
        },
        {
            path: '/demo',
            component: test,
            props: {
                id: 11
            }
        },
        {
            path: '/doPayment',
            component: doPayment,
        },
        {
            path: '/diag',
            component: diagnosePayment,
        },
        {
            path: '/list',
            component: quizList,
            name: 'list',
            beforeEnter: (to, from, next) => {
                if (store.state.app.inited) {
                    if (store.state.user.status) {
                        next();  // Разрешаем доступ к маршруту
                    } else {
                        next('/');  // Перенаправляем на страницу логина
                    }
                }
            }
        },
        {
            path: '/q/:id',
            component: test,
            name: 'quiz',
            beforeEnter: (to, from, next) => {
                if (store.state.app.inited) {
                    if (store.state.user.status) {
                        next();  // Разрешаем доступ к маршруту
                    } else {
                        next('/');  // Перенаправляем на страницу логина
                    }
                }
            }
        },
    ],
    history: createWebHistory()
})