<template>

  <div>
    <div class="card">
      <ul class="list-group list-group-flush">
        <li class="list-group-item  list-group-item-secondary ">
          <h2 class="results_title text-center text-secondary mb-0 py-2">{{ $t('testing.results.title') }} {{ progressPercent }}%
          </h2>
        </li>
        <li class="list-group-item py-3">
          
          <h1 class="text-center">
            <span class="results_score text-danger">{{ score }}</span>
            <span class="mx-2 mb-3 results_devider">/</span>
            <span class="results_length">{{ quiz.question.length }}</span>
          </h1>
        </li>

        <li class="list-group-item  list-group-item-secondary d-flex justify-content-around py-3">
          <button type="button" class="btn btn-outline-secondary btn-sm"
            v-if="showRepeatBtn"
            @click="restart()">

            {{ $t('testing.results.repeatButton') }}</button>

            <button type="button" class="btn btn-primary px-5"            
            @click="quizList()">

            {{ $t('testing.results.moreVariantsButton') }}</button>
         
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "quiz_results",
  data() {
    return {      
    }
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState({
      quiz: state => state.quiz.data,
      attempt: state => state.quiz.attempt,
    },),
    ...mapGetters({
      score: 'score',
      userHasPaidAccess: 'userHasPaidAccess'
    }),
    option() {
      return this.$route.params.id ?? this.id
    },
    progressPercent() {
      return Math.trunc(((this.score) / this.quiz.question.length) * 100)
    },
    showRepeatBtn(){
      return this.progressPercent <= 99
    }
  },
  methods: {
    ...mapActions(['restartQuiz']),
    restart: function () {
      this.restartQuiz({id: this.id})
    },
    quizList: function () {
      if (this.userHasPaidAccess) {
        this.$router.push({name:'list'})
      }else{
        window.location.href = this.$t('testing.results.pricesUrl');
      } 
            
    },
  },


}
</script>

<style scoped>
.results_title {
  font-weight: 200;
}

.results_score {
  font-weight: 600;
  font-size: 5rem;
}

.results_devider {
  font-weight: 200;
  font-size: 3rem;
  color: grey;
}

.results_length {
  font-weight: 400;
  font-size: 4rem;
  color: grey;
}
</style>
